import { Controller } from "@hotwired/stimulus";

import Cleave from "cleave.js";

export default class extends Controller {
  static targets = ["cardNumberInput", "cardExpirationInput", "cardCvvInput"];

  declare cardNumberInputTarget: HTMLInputElement;
  declare cardExpirationInputTarget: HTMLInputElement;
  declare cardCvvInputTarget: HTMLInputElement;

  connect() {
    new Cleave(this.cardNumberInputTarget, { creditCard: true });

    new Cleave(this.cardExpirationInputTarget, {
      date: true,
      datePattern: ["m", "y"],
    });
  }
}
