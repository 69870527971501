import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["filterTypeRadio", "includeVoidsCheckbox"];

  declare filterTypeRadioTargets: Array<HTMLInputElement>;
  declare includeVoidsCheckboxTarget: HTMLInputElement;
  declare hasIncludeVoidsCheckboxTarget: boolean;

  connect() {
    if (this.hasIncludeVoidsCheckboxTarget) this.applyActiveStateClasses(this.includeVoidsCheckboxTarget);

    this.filterTypeRadioTargets.forEach(this.applyActiveStateClasses);
  }

  submitForm() {
    this.form().submit();
  }

  applyActiveStateClasses(eventOrTarget: Event | HTMLInputElement) {
    let target: HTMLInputElement;

    if (eventOrTarget instanceof Event) {
      target = eventOrTarget.target as HTMLInputElement;
    } else {
      target = eventOrTarget;
    }

    const label = target.parentElement;

    if (!(label instanceof HTMLLabelElement))
      throw new Error("Expected parent of input element to be a label, but it wasn't");

    let active = false;

    switch (target.type) {
      case "checkbox":
      case "radio":
        active = target.checked;
        break;
      default:
        console.error(`Tried to toggle active state on unknown input type: ${target.type}`);
        break;
    }

    const activeClasses = target.getAttribute("data-active-classes")!.split(" ");
    const inactiveClasses = target.getAttribute("data-inactive-classes")!.split(" ");

    if (active) {
      label.classList.remove(...inactiveClasses);
      label.classList.add(...activeClasses);
    } else {
      label.classList.remove(...activeClasses);
      label.classList.add(...inactiveClasses);
    }
  }

  checkAllFilterRadioButton() {
    this.filterTypeRadioTargets.find((radio) => radio.value === "all")!.click();
  }

  disableIncludeVoids() {
    if (this.hasIncludeVoidsCheckboxTarget) this.includeVoidsCheckboxTarget.checked = false;
  }

  form() {
    return this.element as HTMLFormElement;
  }
}
