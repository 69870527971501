import { enter, leave } from "el-transition";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["closeButton"];

  declare closeButtonTarget: HTMLButtonElement;

  connect() {
    // Display toast on page load on a very slight delay to make sure it's seen
    setTimeout(() => enter(this.element as HTMLElement), 200);

    // Automatically close the notification after 5 seconds unless disabled
    if (this.element.getAttribute("data-no-autoclose") === null)
      setTimeout(() => leave(this.element as HTMLElement), 5000);
  }

  close() {
    leave(this.element as HTMLElement);
  }
}
