import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nameInput", "addressNameInput"];

  declare nameInputTarget: HTMLInputElement;
  declare addressNameInputTarget: HTMLInputElement;

  connect() {
    this.updateAddressNamePlaceholder();
  }

  updateAddressNamePlaceholder() {
    // Do nothing if we don't have a value to copy over
    const nameInputValue = this.nameInputTarget.value;
    if (nameInputValue === undefined) return;

    // Do nothing if the user has explicitly filled out the address name
    const addressNameInputValue = this.addressNameInputTarget.value;
    if (addressNameInputValue) return;

    this.addressNameInputTarget.setAttribute("placeholder", nameInputValue);
  }
}
