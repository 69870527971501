import { Controller } from "@hotwired/stimulus";

import { Country as CSCCountry, State as CSCState } from "@taylorthurlow/country-state-city";

export default class extends Controller {
  static targets = ["countryInput", "stateInput", "countryIconDiv"];
  static values = {
    defaultStateCode: String,
  };

  declare countryInputTarget: HTMLSelectElement;
  declare stateInputTarget: HTMLSelectElement;
  declare countryIconDivTarget: HTMLDivElement;
  declare hasCountryIconDivTarget: boolean;

  declare defaultStateCodeValue: String;

  // Import types themselves so we can use them when we dynamically import the
  // full library on connect()
  declare Country: typeof CSCCountry;
  declare State: typeof CSCState;

  async connect() {
    // Dynamically import large dependency to avoid including it in the main
    // application bundle, and fetching it only when it is used on connect()
    // TODO: This no longer works under esbuild, figure out how to avoid
    // importing this until the page is loaded
    let { Country, State } = await import(/* webpackPrefetch: true */ "../../lazy_load/country_state_city");
    this.Country = Country;
    this.State = State;

    this.updateCountryIcon();
    this.updateStateInput();
  }

  updateStateInput() {
    const selectedCountry = this.Country.byIsoCode(this.countryInputTarget.value);

    // Disable the field with a helpful message if we don't have a selected country
    if (!selectedCountry) {
      this.stateInputTarget.disabled = true;
      this.stateInputTarget.options.add(new Option("Select a country first", "", true, true));
      return;
    } else {
      this.stateInputTarget.disabled = false;
    }

    const applicableStates = this.State.withinCountry(selectedCountry.isoCode);

    // Some countries may have no states, so set a default value of "not applicable" and disable the field
    if (applicableStates.length == 0) {
      this.stateInputTarget.disabled = true;
      this.stateInputTarget.options.add(
        new Option(`Not applicable to ${selectedCountry.name}`, "Not applicable", true, true),
      );
      return;
    }

    this.stateInputTarget.value = "";
    Array.from(this.stateInputTarget.options).forEach((option) => option.remove());

    const defaultStateCode = this.defaultStateCodeValue;
    applicableStates.forEach((state) => {
      this.stateInputTarget.options.add(
        new Option(state.name, state.isoCode, false, state.isoCode === defaultStateCode),
      );
    });
  }

  updateCountryIcon() {
    if (!this.hasCountryIconDivTarget) return;

    const selectedCountry = this.Country.byIsoCode(this.countryInputTarget.value);
    const defaultIconElement = this.element.querySelector("#country-icon-default") as SVGElement;
    const flagWrapperElement = this.element.querySelector("#country-icon-flag") as HTMLSpanElement;

    if (selectedCountry) {
      flagWrapperElement.innerText = selectedCountry.flag;
      flagWrapperElement.classList.remove("hidden");
      defaultIconElement.classList.add("hidden");
    } else {
      flagWrapperElement.classList.add("hidden");
      defaultIconElement.classList.remove("hidden");
    }
  }
}
