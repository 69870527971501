import { Controller } from "@hotwired/stimulus";

import Cleave from "cleave.js";
require("cleave.js/dist/addons/cleave-phone.us.js");

export default class extends Controller {
  static targets = ["phoneNumberInput"];

  declare phoneNumberInputTarget: HTMLInputElement;

  connect() {
    new Cleave(this.phoneNumberInputTarget, {
      phone: true,
      delimiter: "-",
      phoneRegionCode: "US",
    });
  }
}
