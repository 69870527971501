import { Controller } from "@hotwired/stimulus";

import axios from "axios";
import { enter, leave } from "el-transition";

interface ValidationResponse {
  changed: boolean;
  add1: string;
  add2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

interface Address {
  line1: string;
  line2: string | null;
  city: string;
  region: string;
  country: string;
  postalCode: string;
}

export default class extends Controller {
  static targets = [
    "address1Input",
    "address2Input",
    "cityInput",
    "countryInput",
    "stateInput",
    "postalCodeInput",
    "modalWrapperDiv",
    "modalPrimaryTextDiv",
    "modalContinueButton",
    "modalCancelButton",
    "modalAddressLine1Entered",
    "modalAddressLine2Entered",
    "modalAddressLine3Entered",
    "modalAddressLine4Entered",
    "modalAddressLine1Suggested",
    "modalAddressLine2Suggested",
    "modalAddressLine3Suggested",
    "modalAddressLine4Suggested",
    "modalEnteredAddressRadioButton",
    "modalSuggestedAddressRadioButton",
    "modalEnteredAddressLabel",
    "modalSuggestedAddressLabel",
  ];

  declare address1InputTarget: HTMLInputElement;
  declare address2InputTarget: HTMLInputElement;
  declare cityInputTarget: HTMLInputElement;
  declare countryInputTarget: HTMLSelectElement;
  declare stateInputTarget: HTMLInputElement | HTMLSelectElement;
  declare postalCodeInputTarget: HTMLInputElement;
  declare modalWrapperDivTarget: HTMLDivElement;
  declare modalPrimaryTextDivTarget: HTMLParagraphElement;
  declare modalContinueButtonTarget: HTMLButtonElement;
  declare modalCancelButtonTarget: HTMLButtonElement;
  declare modalAddressLine1EnteredTarget: HTMLSpanElement;
  declare modalAddressLine2EnteredTarget: HTMLSpanElement;
  declare modalAddressLine3EnteredTarget: HTMLSpanElement;
  declare modalAddressLine4EnteredTarget: HTMLSpanElement;
  declare modalAddressLine1SuggestedTarget: HTMLSpanElement;
  declare modalAddressLine2SuggestedTarget: HTMLSpanElement;
  declare modalAddressLine3SuggestedTarget: HTMLSpanElement;
  declare modalAddressLine4SuggestedTarget: HTMLSpanElement;
  declare modalEnteredAddressRadioButtonTarget: HTMLInputElement;
  declare modalSuggestedAddressRadioButtonTarget: HTMLInputElement;
  declare modalEnteredAddressLabelTarget: HTMLLabelElement;
  declare modalSuggestedAddressLabelTarget: HTMLLabelElement;

  async validate(event: Event) {
    if (this.anyMissingFields()) return;
    event.preventDefault();

    const responseData = await this.sendValidateRequest();

    // Do nothing if something went wrong with the request, or if FedEx didn't
    // give us a better address
    if (responseData && responseData.changed) {
      const suggestedAddress = this.addressFromValidationResponse(responseData);
      const useSuggested = await this.promptAddressChangeModal(
        this.addressFromInputs(),
        suggestedAddress,
        "We think this address might be better. Which one would you like to use?",
      );

      this.hideModal();

      if (useSuggested) this.fillFormWithAddress(suggestedAddress);
    }

    (this.element as HTMLFormElement).submit();
  }

  updateModalRadioLabelHighlight() {
    const inactiveClasses = ["border-gray-200"];
    const activeClasses = ["border-blue-400", "ring", "ring-blue-300", "bg-blue-50"];

    // Update first radio button
    if (this.modalEnteredAddressRadioButtonTarget.checked) {
      this.modalEnteredAddressLabelTarget.classList.remove(...inactiveClasses);
      this.modalEnteredAddressLabelTarget.classList.add(...activeClasses);
    } else {
      this.modalEnteredAddressLabelTarget.classList.remove(...activeClasses);
      this.modalEnteredAddressLabelTarget.classList.add(...inactiveClasses);
    }

    // Update second radio button
    if (this.modalSuggestedAddressRadioButtonTarget.checked) {
      this.modalSuggestedAddressLabelTarget.classList.remove(...inactiveClasses);
      this.modalSuggestedAddressLabelTarget.classList.add(...activeClasses);
    } else {
      this.modalSuggestedAddressLabelTarget.classList.remove(...activeClasses);
      this.modalSuggestedAddressLabelTarget.classList.add(...inactiveClasses);
    }
  }

  enableContinueButton() {
    this.modalContinueButtonTarget.removeAttribute("disabled");
  }

  cancelProcess() {
    this.disableSubmitButtonSpinner();
    this.reEnableSubmitButton();
    this.hideModal();
    this.address1InputTarget.focus();
  }

  private anyMissingFields() {
    return [
      this.address1InputTarget,
      this.cityInputTarget,
      this.countryInputTarget,
      this.stateInputTarget,
      this.postalCodeInputTarget,
    ].some((fieldElement) => fieldElement.value.trim() === "");
  }

  private showModal() {
    enter(this.modalWrapperDivTarget);
  }

  private hideModal() {
    leave(this.modalWrapperDivTarget);
  }

  private async promptAddressChangeModal(from: Address, to: Address, message: string): Promise<boolean> {
    this.modalPrimaryTextDivTarget.innerText = message;

    this.modalAddressLine1EnteredTarget.innerText = from.line1;
    this.modalAddressLine2EnteredTarget.innerText = from.line2 || "";
    this.modalAddressLine3EnteredTarget.innerText = `${from.city}, ${from.region} ${from.postalCode}`;
    this.modalAddressLine4EnteredTarget.innerText = from.country;

    this.modalAddressLine1SuggestedTarget.innerText = to.line1;
    this.modalAddressLine2SuggestedTarget.innerText = to.line2 || "";
    this.modalAddressLine3SuggestedTarget.innerText = `${to.city}, ${to.region} ${to.postalCode}`;
    this.modalAddressLine4SuggestedTarget.innerText = to.country;

    this.showModal();

    return new Promise((resolve, reject) => {
      this.modalContinueButtonTarget.addEventListener("click", () => {
        resolve(this.modalSuggestedAddressRadioButtonTarget.checked);
      });
    });
  }

  private async sendValidateRequest(): Promise<ValidationResponse | null> {
    try {
      const csrf = (document.querySelector("meta[name=csrf-token]") as HTMLMetaElement)?.content;
      if (!csrf) console.warn("Missing CSRF token. The validation request will probably 401.");

      const response = await axios.get("/address_validation/validate.json", {
        headers: { "X-XSRF-Token": csrf },
        params: {
          "address[add1]": this.address1InputTarget.value,
          "address[add2]": this.address2InputTarget.value,
          "address[city]": this.cityInputTarget.value,
          "address[state]": this.stateInputTarget.value,
          "address[zip]": this.postalCodeInputTarget.value,
          "address[country]": this.countryInputTarget.value,
        },
      });

      if (response.data instanceof Object) {
        if (response.data.error) {
          console.error(`FedEx 200 but contained error: ${response.data.error}`);
          return null;
        } else {
          return response.data;
        }
      } else {
        console.error("Got non-JSON response from address validation endpoint.");
        return null;
      }
    } catch (error: any) {
      console.error(error);
      if (error.response) console.error(error.response.data);

      return null;
    }
  }

  // Build an Address by reading from the form fields.
  private addressFromInputs(): Address {
    return {
      line1: this.address1InputTarget.value,
      line2: this.address2InputTarget.value,
      city: this.cityInputTarget.value,
      region: this.stateInputTarget.value,
      country: this.countryInputTarget.value,
      postalCode: this.postalCodeInputTarget.value,
    };
  }

  // Build an Address from a ValidationResponse object.
  private addressFromValidationResponse(data: ValidationResponse): Address {
    return {
      line1: data.add1,
      line2: data.add2 || null,
      city: data.city,
      region: data.state,
      country: data.country,
      postalCode: data.zip,
    };
  }

  // Fill the form fields with a given Address's data.
  private fillFormWithAddress(address: Address) {
    this.address1InputTarget.value = address.line1;
    this.address2InputTarget.value = address.line2 || "";
    this.cityInputTarget.value = address.city;
    this.stateInputTarget.value = address.region;
    this.countryInputTarget.value = address.country;
    this.postalCodeInputTarget.value = address.postalCode;
  }

  private disableSubmitButtonSpinner() {
    const spinners = Array.from(this.element.getElementsByClassName("spinner"));

    const event = new Event("stop-spinning");
    spinners.forEach((spinnerSvg) => spinnerSvg.dispatchEvent(event));
  }

  private reEnableSubmitButton() {
    const submitButtons = Array.from(this.element.querySelectorAll('button[type="submit"]'));

    const event = new Event("reenable");
    submitButtons.forEach((submitButton) => submitButton.dispatchEvent(event));
  }
}
