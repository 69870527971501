import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["yearSelect", "startDateInput", "endDateInput"];

  setRangeToYear() {
    const year = this.yearSelectTarget.value;

    this.startDateInputTarget.value = `${year}-01-01`;
    this.endDateInputTarget.value = `${year}-12-31`;
    this.yearSelectTarget.value = "";

    this.highlightInput(this.startDateInputTarget);
    this.highlightInput(this.endDateInputTarget);
  }

  // Temporarily highlight the input field to indicate a change
  highlightInput(input) {
    const klass = "bg-yellow-100";
    input.classList.add(klass);

    setTimeout(() => {
      input.classList.remove(klass);
    }, 350);
  }
}
