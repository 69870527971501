import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["passwordInput", "iconOnSvg", "iconOffSvg"];

  declare passwordInputTarget: HTMLInputElement;
  declare iconOnSvgTarget: SVGElement;
  declare iconOffSvgTarget: SVGElement;

  show() {
    this.passwordInputTarget.setAttribute("type", "text");

    this.iconOnSvgTarget.classList.add("hidden");
    this.iconOffSvgTarget.classList.remove("hidden");
  }

  hide() {
    this.passwordInputTarget.setAttribute("type", "password");

    this.iconOnSvgTarget.classList.remove("hidden");
    this.iconOffSvgTarget.classList.add("hidden");
  }
}
