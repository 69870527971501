import { Application } from "@hotwired/stimulus";

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

import LocalTime from "local-time";
LocalTime.start();

export { application };
