import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["spinnerSvg"];

  declare spinnerSvgTarget: SVGElement;

  enableSpinner() {
    this.spinnerSvgTarget.classList.remove("hidden");
    this.spinnerSvgTarget.classList.add("inline");

    this.spinnerSvgTarget.addEventListener("stop-spinning", () => {
      this.spinnerSvgTarget.classList.add("hidden");
      this.spinnerSvgTarget.classList.remove("inline");
    });
  }
}
