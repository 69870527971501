import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["radioButtonLabel"];

  declare radioButtonLabelTargets: Array<HTMLLabelElement>;

  connect() {
    this.updateClasses();
  }

  updateClasses() {
    this.radioButtonLabelTargets.forEach((label) => {
      const input = label.querySelector("input[type=radio]") as HTMLInputElement | undefined;
      if (!input) throw new Error("Unable to find radio input within label");

      this.updateClassesFor(label, input.checked);

      // Update all descendent elements
      Array.from(label.getElementsByTagName("*")).forEach((child) => this.updateClassesFor(child, input.checked));
    });
  }

  updateClassesFor(element: Element, active: boolean) {
    if (!(element instanceof HTMLElement)) return;

    const inactiveClasses = element.dataset["form-RadioButtonGroupInactiveValue"];
    const activeClasses = element.dataset["form-RadioButtonGroupActiveValue"];

    if (!inactiveClasses && !activeClasses) return;

    if (active) {
      if (activeClasses) element.classList.add(...activeClasses.split(" "));
      if (inactiveClasses) element.classList.remove(...inactiveClasses.split(" "));
    } else {
      if (inactiveClasses) element.classList.add(...inactiveClasses.split(" "));
      if (activeClasses) element.classList.remove(...activeClasses.split(" "));
    }
  }
}
