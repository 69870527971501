import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitButton"];

  declare submitButtonTarget: HTMLButtonElement;

  disableSubmitButton() {
    this.submitButtonTarget.setAttribute("disabled", "disabled");

    this.submitButtonTarget.addEventListener("reenable", () => {
      this.submitButtonTarget.removeAttribute("disabled");
    });
  }
}
