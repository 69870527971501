import { Controller } from "@hotwired/stimulus";

const activeDropAreaClasses = "bg-gray-100";

export default class extends Controller {
  static targets = ["dropAreaDiv", "fileInput", "fileNameDiv", "uploadFileLink"];

  declare dropAreaDivTarget: HTMLDivElement;
  declare fileInputTarget: HTMLInputElement;
  declare fileNameDivTarget: HTMLDivElement;
  declare uploadFileLinkTarget: HTMLSpanElement;

  updateLabel() {
    const files = this.fileInputTarget.files;
    if (!files || files.length === 0) return;

    this.fileNameDivTarget.innerHTML = files[0].name;
  }

  darkenDropArea(event: Event) {
    this.preventDefaults(event);
    this.dropAreaDivTarget.classList.add(activeDropAreaClasses);
  }

  lightenDropArea(event: Event) {
    this.preventDefaults(event);
    this.dropAreaDivTarget.classList.remove(activeDropAreaClasses);
  }

  openFileBrowseDialog(event: Event) {
    this.fileInputTarget.click();
  }

  attachFile(event: DragEvent) {
    const files = event.dataTransfer?.files;
    if (!files || files.length === 0) return;

    this.fileInputTarget.files = files;
    this.updateLabel();
  }

  clearFile(event: Event) {
    this.fileInputTarget.value = "";
  }

  private preventDefaults(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
