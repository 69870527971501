import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["formItem"];

  static values = { successRedirect: String };

  declare formItemTargets: Array<HTMLDivElement>;
  declare successRedirectValue: string;

  connect() {
    this.reindexItems();

    this.formItemTargets.forEach((formItem) => {
      formItem.addEventListener("orderRemakeForm.removeItem", () => this.reindexItems());
    });
  }

  submit(event: Event) {
    event.preventDefault();
    const salesforceDescriptionFields = ["OrderNumber", "order_type", "additional_services"];
    const form = this.element as HTMLFormElement;
    const descriptionField = form.elements.namedItem("description") as HTMLInputElement;

    Array.from(form.elements).forEach((element) => {
      if (!(element instanceof HTMLInputElement)) return;
      const inputElement = element as HTMLInputElement;

      if (salesforceDescriptionFields.includes(inputElement.name))
        descriptionField.value = `${descriptionField.value}${inputElement.name}: ${inputElement.value}\n\n`;
    });

    if (form.checkValidity()) {
      fetch(form.getAttribute("action")!, {
        method: "POST",
        body: new FormData(form),
      })
        .then((response) => {
          window.location.href = this.successRedirectValue;
        })
        .catch((error) => console.error(error));
    }
  }

  addNewItem(event: Event) {
    event.preventDefault();
    const sourceElement = this.element.querySelector('div[data-group-id="1"]')!;
    const clone = sourceElement.cloneNode(true) as HTMLDivElement;

    clone.querySelectorAll("input").forEach((element) => {
      (element as HTMLInputElement).value = "";
    });

    clone.addEventListener("orderRemakeForm.removeItem", () => this.reindexItems());

    sourceElement.parentElement!.appendChild(clone);

    this.reindexItems();
  }

  reindexItems() {
    this.formItemTargets.forEach((item, index) => {
      const newIndex = (index + 1).toString();
      item.setAttribute("data-group-id", newIndex);

      const label = item.querySelector('label[for^="Item "]')!;
      label.setAttribute("for", `Item ${newIndex}`);
      label.innerHTML = `Item ${newIndex}`;

      const fileNameInput = item.querySelector('[id^="image_"]')!;
      fileNameInput.setAttribute("id", `image_${newIndex}`);
      fileNameInput.setAttribute("name", `image_${newIndex}`);

      const fileNameInputLabel = item.querySelector('label[for^="image_"]')!;
      fileNameInputLabel.setAttribute("for", `image_${newIndex}`);

      const sizeInput = item.querySelector('[id^="size_"]')!;
      sizeInput.setAttribute("id", `size_${newIndex}`);
      sizeInput.setAttribute("name", `size_${newIndex}`);

      const sizeInputLabel = item.querySelector('label[for^="size_"]')!;
      sizeInputLabel.setAttribute("for", `size_${newIndex}`);

      var quantityInput = item.querySelector('[id^="qty_"')!;
      quantityInput.setAttribute("id", `qty_${newIndex}`);
      quantityInput.setAttribute("name", `qty_${newIndex}`);

      const quantityInputLabel = item.querySelector('label[for^="qty_"]')!;
      quantityInputLabel.setAttribute("for", `qty_${newIndex}`);

      const additionalServicesInput = item.querySelector('[id^="additional_services_"]')!;
      additionalServicesInput.setAttribute("id", `additional_services_${newIndex}`);
      additionalServicesInput.setAttribute("name", `additional_services_${newIndex}`);

      const additionalServicesInputLabel = item.querySelector('label[for^="additional_services_"]')!;
      additionalServicesInputLabel.setAttribute("for", `additional_services_${newIndex}`);

      const removeButton = item.querySelector(".remove-group-button") as HTMLButtonElement;
      removeButton.setAttribute("data-group-id", newIndex);

      // Hide the remove button on item if it's the only item left - if we
      // remove the last one, we don't have one to clone
      if (this.formItemTargets.length <= 1 && newIndex == "1") {
        removeButton.style.display = "none";
      } else {
        removeButton.style.display = "block";
      }
    });
  }
}
