import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.querySelectorAll("input, select, textarea").forEach((input) => {
      if (input.getAttribute("data-errors") === "true") {
        input.classList.remove("border-gray-300", "focus:ring-blue-300", "focus:border-blue-400", "focus:ring");
        input.classList.add("ring-0", "focus:ring", "border-red-400", "focus:ring-red-300", "focus:border-red-400");
      }
    });
  }
}
