import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["passwordInput", "newPasswordInput", "newPasswordWrapperDiv"];
  static values = {
    minPasswordLength: Number,
  };

  declare passwordInputTarget: HTMLInputElement;
  declare newPasswordInputTarget: HTMLInputElement;
  declare newPasswordWrapperDivTarget: HTMLDivElement;
  declare minPasswordLengthValue: Number;

  connect() {}

  showNewPasswordField(event: Event) {
    // If the field is already shown, allow the form to be submitted
    if (!this.newPasswordWrapperDivTarget.classList.contains("hidden")) return;

    const password = this.passwordInputTarget.value;
    const minimumPasswordLength = this.minPasswordLengthValue;
    if (password.length > 0 && password.length < minimumPasswordLength) {
      event.preventDefault();
      this.resetSubmitButton();
      this.enableNewPassword();
      this.newPasswordInputTarget.focus();
    }
  }

  enableNewPassword() {
    this.newPasswordInputTarget.disabled = false;
    // Use "display: contents" to avoid wrapper interrupting grid layout
    this.newPasswordWrapperDivTarget.classList.add("contents");
    this.newPasswordWrapperDivTarget.classList.remove("hidden");
  }

  disableNewPassword() {
    this.newPasswordInputTarget.disabled = true;
    this.newPasswordWrapperDivTarget.classList.add("hidden");
    this.newPasswordWrapperDivTarget.classList.remove("contents");
  }

  // Re-enable the submit button and disable the spinner
  private resetSubmitButton() {
    const submitButton = this.element.querySelector("button[type='submit']");

    if (submitButton) {
      // Re-enable button
      const reEnableEvent = new Event("reenable");
      submitButton.dispatchEvent(reEnableEvent);

      // Stop spinners
      const spinners = Array.from(submitButton.getElementsByClassName("spinner"));
      const stopSpinningEvent = new Event("stop-spinning");
      spinners.forEach((spinnerSvg) => spinnerSvg.dispatchEvent(stopSpinningEvent));
    } else {
      console.error("Unable to find submit button and re-enable it.");
    }
  }
}
