import { enter, leave } from "el-transition";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle", "menu"];

  declare toggleTarget: HTMLElement;
  declare menuTarget: HTMLElement;

  toggleMenu(event: Event) {
    // These listener callbacks need to be defined here because we need to be
    // able to have a constant reference to the callback function so we can
    // properly remove it from the document, AND be able to refer to the
    // controller's targets. Both functions must be able to refer to one
    // another as a constant.
    const outsideClickListener = (outsideClickEvent: Event) => {
      // If the menu isn't open then we don't need to bother checking if we
      // should close it
      if (!this.menuOpen()) return;

      // The click event from outsideClickListener might be the same click
      // event that triggered this toggleMenu in the first place, so we need to
      // ignore this otherwise the menu will open, the event will bubble up,
      // and then the menu will close.
      if (outsideClickEvent === event) return;

      let clickedElement = outsideClickEvent.target;
      if (!(clickedElement instanceof Element)) return;

      // Determine if we're clicking inside the button or inside the menu
      if (
        clickedElement === this.toggleTarget ||
        this.toggleTarget.contains(clickedElement) ||
        !this.menuTarget.contains(clickedElement)
      ) {
        leave(this.menuTarget);
        removeOutsideClickListener();
      }
    };

    const removeOutsideClickListener = () => {
      document.removeEventListener("click", outsideClickListener);
    };

    if (this.menuOpen()) {
      leave(this.menuTarget);
    } else {
      enter(this.menuTarget);
      document.addEventListener("click", outsideClickListener);
    }
  }

  menuOpen(): boolean {
    return !this.menuTarget.classList.contains("hidden");
  }
}
