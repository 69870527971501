import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="product-navigation"
export default class extends Controller {
  static targets = ["title", "caption", "img"]

  declare titleTarget: HTMLElement;
  declare captionTarget: HTMLElement;
  declare imgTarget: HTMLElement;
  
  setPreview(event: Event) {
    const currentTarget = event.currentTarget as HTMLElement;

    const title = currentTarget && currentTarget.dataset && currentTarget.dataset.title ?
      currentTarget.dataset.title :
      '';
    
    const caption = currentTarget && currentTarget.dataset && currentTarget.dataset.caption ?
      currentTarget.dataset.caption :
      '';

    const img = currentTarget && currentTarget.dataset && currentTarget.dataset.img ?
       `https://bayphoto.com${currentTarget.dataset.img}` :
      '';

    this.titleTarget.textContent = title;
    this.captionTarget.textContent = caption;
    this.imgTarget.setAttribute('src', img);

    if (img !== '') {
      this.imgTarget.classList.remove('hidden')
    } else {
      this.imgTarget.classList.add('hidden')
    }
  }

  clearPreview() {
    this.titleTarget.textContent = '';
    this.captionTarget.textContent = '';
    this.imgTarget.setAttribute('src', '');
  }
}
