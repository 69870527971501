import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["migrationNoticeDiv", "migrationNoticeMessageDiv", "migrationNoticeArrowDiv"];
  static values = {
    emphasizeMigration: Boolean,
  };

  declare migrationNoticeDivTarget: HTMLDivElement;
  declare migrationNoticeMessageDivTarget: HTMLDivElement;
  declare migrationNoticeArrowDivTarget: HTMLDivElement;

  declare emphasizeMigrationValue: Boolean;

  connect() {
    if (this.emphasizeMigrationValue) this.emphasizeMigrationNotice();
  }

  async emphasizeMigrationNotice(delayMilliseconds: number = 200) {
    setTimeout(() => {
      this.migrationNoticeDivTarget.classList.add("animate-shake-and-stop");
      this.migrationNoticeMessageDivTarget.classList.add("bg-red-100");
      this.migrationNoticeArrowDivTarget.classList.remove("hidden");
    }, delayMilliseconds);
  }
}
